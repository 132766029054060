import { render, staticRenderFns } from "./index.vue?vue&type=template&id=6c2d2e88&scoped=true"
var script = {}
import style0 from "./index.vue?vue&type=style&index=0&id=6c2d2e88&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c2d2e88",
  null
  
)

export default component.exports